<div class="flex-column delegate-page">
  <div class="content">
    <div class="flex-column manual">
      <div class="flex-column delegate">
        <a [routerLink]="'/account/' + activeAccount?.address"><button class="flex-row back"><img
              src="../../../../../assets/img/left-arrow.svg">BACK</button></a>
        <h1>Delegating<ng-container *ngIf="!this.isMobile"> & Staking</ng-container></h1>
        <a class="stake" href="{{this.CONSTANTS.MAINNET ? 'https://stake.tezos.com' : 'https://stake-ghostnet.tezos.com'}}" target="_blank"><button class="flex-row">Stake <img src="../../../../../assets/img/external-link.svg"></button></a>
        <div class="flex-row toolbar"><span class="button blue" (click)="toggleCustom()">Enter Custom Baker</span><span
            *ngIf="!delegates?.length" class="button blue" (click)="this.delegateService.getDelegates()">Reload</span>
        </div>
        <ng-container *ngIf="isShowingCustom">
          <span>Address or domain of a registered baker</span>
          <div class="custom">
            <input [(ngModel)]="customAddress" placeholder="Custom baker address" /><button class="button" (click)="stake({address: customAddress})">DELEGATE</button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flex-column list">
      <ng-container *ngFor="let delegate of delegates">
        <div class="flex-row delegate desktop">
          <img class="logo" [src]="delegate.logo ? delegate.logo : ''" />
          <span class="name">{{ delegate.name }}</span>
          <span class="fee">SPLIT:&nbsp;<b class="value">{{ toPercent(delegate?.fee) }}</b></span>
          <span class="freespace" [class.insufficent]="delegate.freeSpace < balanceXTZ">SPACE:&nbsp;<b class="value">{{ round(delegate?.freeSpace) + ' tez' }}</b></span>
          <span class="estROI">EST REWARDS:&nbsp;<b class="value">{{ toPercent(delegate?.estimatedRoi) }}</b></span>
          <span class="accuracy">ACCURACY:&nbsp;<b class="value">{{ delegate?.payoutAccuracy }}</b></span>
          <button *ngIf="delegate?.address !== activeAccount?.delegate" class="button" (click)="stake(delegate)">DELEGATE</button>
          <span class="current" *ngIf="delegate?.address === activeAccount?.delegate">CURRENT</span>
        </div>
        <div class="flex-row delegate mobile">
          <div class="flex-column props">
            <img class="logo" [src]="delegate.logo ? delegate.logo : ''" />
            <span class="name">{{ delegate.name }}</span>
            <span class="fee">SPLIT:&nbsp;<b class="value">{{ toPercent(delegate?.fee) }}</b></span>
            <span class="freespace" [class.insufficent]="delegate.freeSpace < balanceXTZ">SPACE:&nbsp;<b class="value">{{ round(delegate?.freeSpace) + ' tez' }}</b></span>
            <span class="estROI">EST REWARDS:&nbsp;<b class="value">{{ toPercent(delegate?.estimatedRoi) }}</b></span>
          </div>
          <button *ngIf="delegate?.address !== activeAccount?.delegate" class="button" (click)="stake(delegate)">DELEGATE</button>
          <span class="current" *ngIf="delegate?.address === activeAccount?.delegate">CURRENT</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>